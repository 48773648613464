body{
  /*font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;*/
}
.content-wrapper {
  min-height: 100%;
  background-color: #ecf0f5;
  z-index: 800;
}
.content-header {
  position: relative;
  padding: 15px 15px 0 15px;
}
.content {
  min-height: 250px;
  padding: 15px;
    padding-right: 15px;
    padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.box {
  position:relative;
  border-radius:3px;
  background:#ffffff;
  border-top:3px solid #d2d6de;
  margin-bottom:20px;
  width:100%;
  box-shadow:0 1px 1px rgba(0,0,0,0.1)
 }
 .box.box-primary {
  border-top-color:#3c8dbc
 }
 .box.box-info {
  border-top-color:#00c0ef
 }
 .box.box-danger {
  border-top-color:#dd4b39
 }
 .box.box-warning {
  border-top-color:#f39c12
 }
 .box.box-success {
  border-top-color:#00a65a
 }
 .box.box-default {
  border-top-color:#d2d6de
 }
 .box.collapsed-box .box-body,
 .box.collapsed-box .box-footer {
  display:none
 }
 .box .nav-stacked>li {
  border-bottom:1px solid #f4f4f4;
  margin:0
 }
 .box .nav-stacked>li:last-of-type {
  border-bottom:none
 }
 .box.height-control .box-body {
  max-height:300px;
  overflow:auto
 }
 .box .border-right {
  border-right:1px solid #f4f4f4
 }
 .box .border-left {
  border-left:1px solid #f4f4f4
 }
 .box.box-solid {
  border-top:0
 }
 .box.box-solid>.box-header .btn.btn-default {
  background:transparent
 }
 .box.box-solid>.box-header .btn:hover,
 .box.box-solid>.box-header a:hover {
  background:rgba(0,0,0,0.1)
 }
 .box.box-solid.box-default {
  border:1px solid #d2d6de
 }
 .box.box-solid.box-default>.box-header {
  color:#444;
  background:#d2d6de;
  background-color:#d2d6de
 }
 .box.box-solid.box-default>.box-header a,
 .box.box-solid.box-default>.box-header .btn {
  color:#444
 }
 .box.box-solid.box-primary {
  border:1px solid #3c8dbc
 }
 .box.box-solid.box-primary>.box-header {
  color:#fff;
  background:#3c8dbc;
  background-color:#3c8dbc
 }
 .box.box-solid.box-primary>.box-header a,
 .box.box-solid.box-primary>.box-header .btn {
  color:#fff
 }
 .box.box-solid.box-info {
  border:1px solid #00c0ef
 }
 .box.box-solid.box-info>.box-header {
  color:#fff;
  background:#00c0ef;
  background-color:#00c0ef
 }
 .box.box-solid.box-info>.box-header a,
 .box.box-solid.box-info>.box-header .btn {
  color:#fff
 }
 .box.box-solid.box-danger {
  border:1px solid #dd4b39
 }
 .box.box-solid.box-danger>.box-header {
  color:#fff;
  background:#dd4b39;
  background-color:#dd4b39
 }
 .box.box-solid.box-danger>.box-header a,
 .box.box-solid.box-danger>.box-header .btn {
  color:#fff
 }
 .box.box-solid.box-warning {
  border:1px solid #f39c12
 }
 .box.box-solid.box-warning>.box-header {
  color:#fff;
  background:#f39c12;
  background-color:#f39c12
 }
 .box.box-solid.box-warning>.box-header a,
 .box.box-solid.box-warning>.box-header .btn {
  color:#fff
 }
 .box.box-solid.box-success {
  border:1px solid #00a65a
 }
 .box.box-solid.box-success>.box-header {
  color:#fff;
  background:#00a65a;
  background-color:#00a65a
 }
 .box.box-solid.box-success>.box-header a,
 .box.box-solid.box-success>.box-header .btn {
  color:#fff
 }
 .box.box-solid>.box-header>.box-tools .btn {
  border:0;
  box-shadow:none
 }
 .box.box-solid[class*='bg']>.box-header {
  color:#fff
 }
 .box .box-group>.box {
  margin-bottom:5px
 }
 .pad {
  padding: 10px;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px;
}

.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
}

.box-footer {
  border-top-left-radius:0;
  border-top-right-radius:0;
  border-bottom-right-radius:3px;
  border-bottom-left-radius:3px;
  border-top:1px solid #f4f4f4;
  padding:10px;
  background-color:#fff
 }
/********************/
.widget-user-header {
  padding: 10px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  text-align: center;
}
 .profile-user-img {
  margin: 0 auto;
  width: 100px;
  padding: 3px;
  border: 3px solid #d2d6de;
}
.profile-username {
  font-size: 16px;
  margin-top: 2px;
}
.bg-primary1 {
  color: #555;
}

.separador_celeste{
  background-color: #CAD3DD;
}
/*************/
.box-header > .fa, 
.box-header > .glyphicon, 
.box-header > .ion, 
.box-header .box-title {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  line-height: 1;
}
.btn-box-tool {
  padding: 5px;
  font-size: 12px;
  background: transparent;
  color: #97a0b3;
}
.box-header.with-border {
  border-bottom: 1px solid #f4f4f4;
}
.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
}
