.container1 {
  max-width: 600px;
}
.previewBlock1 {
  display: block;
  cursor: pointer;
  width: 100px;
  height: 100px;        
  margin: 0 auto 20px;
  background-position: center center;
  background-size: cover;
}
.file-img {
  margin: 0px auto; /*centrar un img en un div*/
  width: 80%;
  padding: 3px;
  border: 3px solid #d2d6de;
}
.img-circle1 {
  border-radius: 50%;
}

.box1 {
  margin: 10px 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*border: 3px dotted #a3a3a3;
  border-radius: 5px;*/
}