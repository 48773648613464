.datagrid-mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /*opacity: 0.3;*/
    opacity: 0.7;
    filter: alpha(opacity=30);
    display: none;
    /*background-color: white;*/
  }
  .datagrid-mask-msg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    padding: 10px 10px 10px 10px;
    width: auto;
    /*height: 16px;*/
    border: 2px solid #ccc;
    display: none;
    background-color: white;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }