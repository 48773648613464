input {
  /*border: 1px solid #999;
  padding: 8px;*/
}
.no-autocomplete {
  color: #999;
  padding: 8px;
}
.autocomplete {
  /*border: 1px solid #999;*/
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 191px; /*143px  2228px*/
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}
.autocomplete li {
  padding: 8px;
}
/*.autocomplete > .active,
.autocomplete li:hover {
  background-color: darkgray;
  cursor: pointer;
  font-weight: 700;
}*/
/*.autocomplete li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}*/
/*------------- cargando a la derecha del control input ------------*/
.control.is-loading::after {
  position: absolute !important;
  right: .625em;
  top: .625em;
  z-index: 4;
}
.button.is-loading::after, 
.control.is-loading::after, 
.loader, 
.select.is-loading::after {
  -webkit-animation: spinAround .5s infinite linear;
  animation: spinAround .5s infinite linear;
  border: 2px solid #dbdbdb;
    border-top-color: rgb(219, 219, 219);
    border-right-color: rgb(219, 219, 219);
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
}
.input, .select select, .textarea {
  background-color: #fff;
  border-color: #dbdbdb;
  border-radius: 4px;
  color: #363636;
}
.autocomplete-item {
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}
/*************/
.p-autocomplete-panel {
  /*position: absolute;
  top: 0;
  left: 0;
  overflow: auto;*/
}
.p-autocomplete-panel {
  color: #495057;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 5px #777;
}

.nu_list-item--focus, 
.nu_list-item--selected:hover, 
.nu_list-item:hover {
  background-color: #ededed; /*#ededed*/
  transition: background-color .2s ease-out;
}
.nu_list-item {
  cursor: pointer;
  position: relative;
  padding: 8px 12px;
  border-bottom: 1px solid #e4e4e4;
  outline: none;
}