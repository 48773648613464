.textpeq{
  width: 50px;
}
.svgClass g #layer2 path{
  fill: red;
}
.svgClass #layer2{
  fill: red;
}
.resalte:hover{
  fill:mediumvioletred;
  color:#0d99ff;
  background-color: #0d99ff;
}
.di{
  position: absolute;
  border: 3px solid transparent;
}
/* recuadro de resalte, alrededor*/
.resalte1:hover {
  margin: auto;
  border: 3px solid #0d99ff;
  cursor: pointer;
}

/*resalta alrededor del diente, sustito*/
.resalte2:hover {
  stroke-dasharray: 50 0;
  stroke-width: 3px;
  stroke-dashoffset: 0;
  stroke: #0d99ff;
}