#dibujar #pieza {
  max-width: 60px;
  position: relative;
}
#dibujar #pieza img {
width: 100%;
vertical-align: middle; 
}
#dibujar #pieza img.rotar {
-webkit-transform: rotate(180deg);
-ms-transform: rotate(180deg);
transform: rotate(180deg); 
}

