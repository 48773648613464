/*.side-menur {
  padding: 7px 7px 7px 7px;
}*/
.side-menur .bloque{
  padding: 5px;
}
.side-menur h3{
  position: relative;
  border-top: 1px solid #dfe1e2;
  line-height: normal;
  color: #1A6986;
  margin: 0;
  padding: 0.5rem 1rem;
  background-color: transparent;
}
.side-menur h3::before {
  background-color: #1A6986;
  border-radius: 99rem;
  content: '';
  display: block;
  position: absolute;
  left: .05rem;
  top: 0.2rem;
  width: 0.25rem;
  height: 90%;
}

.side-menur .d-inline-block {
  display: inline-block !important;
}

/* item ckeched */
.side-menur .checked-item {
  /*color: #1A6986 !important;*/
  font-weight: bold;
}
/* item no seleccionado*/
.side-menur .not-checked-item {
  
  font-weight: normal;
}

/*.side-menur label:hover{
  background-color: #e9e9ed;
}*/