
[class^="pick-i-"]:before,
[class*=" pick-i-"]:before {
  font-family: "datedropper" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pick-i-lng:before {
  content: "\6a";
}
.pick-i-lkd:before {
  content: "\62";
}
.pick-i-ckd:before {
  content: "\65";
}
.pick-i-r:before {
  content: "\66";
}
.pick-i-l:before {
  content: "\68";
}
.pick-i-min:before {
  content: "\61";
}
.pick-i-exp:before {
  content: "\63";
}
.picker-input {
  cursor: text;
}
.picker-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9998;
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.4s ease, visibility 0.4s ease;
  -moz-transition: opacity 0.4s ease, visibility 0.4s ease;
  -ms-transition: opacity 0.4s ease, visibility 0.4s ease;
  -o-transition: opacity 0.4s ease, visibility 0.4s ease;
}
.picker-modal-overlay.tohide {
  opacity: 0;
  visibility: hidden;
}
div.datedropper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  line-height: 1;
  font-family: sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0;
  visibility: hidden;
  margin-top: -8px;
  transform-style: preserve-3d;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
div.datedropper:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: -8px;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  -moz-transform: translateX(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) rotate(45deg);
  -o-transform: translateX(-50%) rotate(45deg);
  border-top-left-radius: 4px;
}
div.datedropper.picker-focus {
  opacity: 1;
  visibility: visible;
  margin-top: 8px;
}
div.datedropper.picker-modal {
  top: 50%!important;
  left: 50%!important;
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  -o-transform: translate(-50%, -50%) !important;
  position: fixed!important;
  margin: 0!important;
}
div.datedropper.picker-modal:before {
  display: none;
}
div.datedropper .picker {
  overflow: hidden;
}
div.datedropper .picker ul {
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: pointer;
}
div.datedropper .picker ul.pick {
  position: relative;
  overflow: hidden;
  max-height: 100px;
}
div.datedropper .picker ul.pick:nth-of-type(2) {
  box-shadow: 0 1px rgba(0, 0, 0, 0.06);
}
div.datedropper .picker ul.pick li {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: .5;
  display: block;
}
div.datedropper .picker ul.pick li.pick-afr {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
}
div.datedropper .picker ul.pick li.pick-bfr {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
}
div.datedropper .picker ul.pick li.pick-sl {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  z-index: 1;
}
div.datedropper .picker ul.pick li span {
  font-size: 16px;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  bottom: 24px;
}
div.datedropper .picker ul.pick .pick-arw {
  position: absolute;
  top: 0;
  height: 100%;
  width: 25%;
  font-size: 10px;
  text-align: center;
  display: block;
  z-index: 10;
  cursor: pointer;
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  opacity: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
}
div.datedropper .picker ul.pick .pick-arw i {
  line-height: 0;
  top: 50%;
  position: relative;
  display: block;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
div.datedropper .picker ul.pick .pick-arw.pick-arw-s1:hover {
  opacity: 1;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
div.datedropper .picker ul.pick .pick-arw.pick-arw-r {
  right: 0;
}
div.datedropper .picker ul.pick .pick-arw.pick-arw-r i {
  right: 0;
}
div.datedropper .picker ul.pick .pick-arw.pick-arw-l {
  left: 0;
}
div.datedropper .picker ul.pick .pick-arw.pick-arw-l i {
  left: 0;
}
div.datedropper .picker ul.pick .pick-arw.pick-arw-s2.pick-arw-r {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
}
div.datedropper .picker ul.pick .pick-arw.pick-arw-s2.pick-arw-l {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
}
@media only screen and (max-width: 480px) {
  div.datedropper .picker ul.pick .pick-arw {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    opacity: 0.4;
  }
}
div.datedropper .picker ul.pick.pick-m,
div.datedropper .picker ul.pick.pick-y,
div.datedropper .picker ul.pick.pick-l {
  height: 60px;
  line-height: 60px;
}
div.datedropper .picker ul.pick.pick-m {
  font-size: 32px;
}
div.datedropper .picker ul.pick.pick-y {
  font-size: 24px;
}
div.datedropper .picker ul.pick.pick-y.pick-jump .pick-arw.pick-arw-s1.pick-arw-r i {
  right: 16px;
}
div.datedropper .picker ul.pick.pick-y.pick-jump .pick-arw.pick-arw-s1.pick-arw-l i {
  left: 16px;
}
div.datedropper .picker ul.pick.pick-y.pick-jump .pick-arw.pick-arw-s2.pick-arw-r,
div.datedropper .picker ul.pick.pick-y.pick-jump .pick-arw.pick-arw-s2.pick-arw-l {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}
div.datedropper .picker ul.pick.pick-y.pick-jump .pick-arw:hover {
  -webkit-transform: scale(1.6);
  -moz-transform: scale(1.6);
  -ms-transform: scale(1.6);
  -o-transform: scale(1.6);
}
div.datedropper .picker ul.pick.pick-d {
  height: 100px;
  line-height: 80px;
  font-size: 64px;
  font-weight: bold;
}
div.datedropper .picker ul.pick.pick-l {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  font-size: 18px;
  font-weight: bold;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(32px);
  -moz-transform: translateY(32px);
  -ms-transform: translateY(32px);
  -o-transform: translateY(32px);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}
div.datedropper .picker ul.pick.pick-l.visible {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}
div.datedropper .picker ul.pick:hover .pick-arw {
  opacity: 0.6;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}
div.datedropper .picker ul.pick.pick-d:hover,
div.datedropper .picker ul.pick.pick-y:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
div.datedropper .picker .pick-lg {
  z-index: 1;
  margin: 0 auto;
  max-height: 0;
  overflow: hidden;
}
div.datedropper .picker .pick-lg.down {
  animation: down .8s ease;
}
div.datedropper .picker .pick-lg .pick-h {
  opacity: 0.4;
}
div.datedropper .picker .pick-lg ul:after {
  content: "";
  display: table;
  clear: both;
}
div.datedropper .picker .pick-lg ul li {
  float: left;
  text-align: center;
  width: 14.285714286%;
  line-height: 36px;
  height: 36px;
  font-size: 14px;
}
div.datedropper .picker .pick-lg ul.pick-lg-h {
  padding: 0 16px;
}
div.datedropper .picker .pick-lg ul.pick-lg-b {
  padding: 16px;
}
div.datedropper .picker .pick-lg ul.pick-lg-b li {
  cursor: pointer;
  position: relative;
  z-index: 1;
}
div.datedropper .picker .pick-lg ul.pick-lg-b li:before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 48px;
  height: 48px;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  -moz-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  -o-transform: translate(-50%, -50%) scale(0);
}
div.datedropper .picker .pick-lg ul.pick-lg-b li.pick-v:hover {
  text-decoration: underline;
}
div.datedropper .picker .pick-lg ul.pick-lg-b li.pick-lk:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 4px;
  right: 4px;
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}
div.datedropper .picker .pick-lg ul.pick-lg-b li.pick-sl {
  font-size: 24px;
}
div.datedropper .picker .pick-lg ul.pick-lg-b li.pick-sl:before {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
}
div.datedropper .picker .pick-btns {
  margin: -1px;
  position: relative;
  z-index: 2;
  height: 56px;
}
div.datedropper .picker .pick-btns div {
  cursor: pointer;
  line-height: 0;
}
div.datedropper .picker .pick-btns .pick-submit {
  margin: 0 auto;
  width: 56px;
  height: 56px;
  line-height: 64px;
  border-radius: 56px;
  font-size: 24px;
  cursor: pointer;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-align: center;
  position: relative;
  top: 0;
}
div.datedropper .picker .pick-btns .pick-submit:after {
  font-family: "datedropper" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 60px;
  content: "\65";
}
div.datedropper .picker .pick-btns .pick-submit:hover {
  top: 4px;
  box-shadow: 0 0 0 16px rgba(0, 0, 0, 0.04), 0 0 0 8px rgba(0, 0, 0, 0.04);
}
div.datedropper .picker .pick-btns .pick-btn {
  position: absolute;
  width: 32px;
  height: 32px;
  bottom: 0;
  text-align: center;
  line-height: 38px;
  font-size: 16px;
  margin: 8px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.03);
}
div.datedropper .picker .pick-btns .pick-btn:hover {
  background: #FFF;
  -webkit-box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
div.datedropper .picker .pick-btns .pick-btn:after {
  font-family: "datedropper" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div.datedropper .picker .pick-btns .pick-btn.pick-btn-sz {
  right: 0;
  transform-origin: right bottom;
}
div.datedropper .picker .pick-btns .pick-btn.pick-btn-sz:after {
  content: "\63";
}
div.datedropper .picker .pick-btns .pick-btn.pick-btn-lng {
  left: 0;
  transform-origin: left bottom;
}
div.datedropper .picker .pick-btns .pick-btn.pick-btn-lng:after {
  content: "\6a";
}
div.datedropper.picker-lg {
  width: 300px!important;
}
div.datedropper.picker-lg ul.pick.pick-d {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  max-height: 0!important;
}
div.datedropper.picker-lg .pick-lg {
  max-height: 320px;
}
div.datedropper.picker-lg .pick-btns .pick-btn.pick-btn-sz:after {
  content: "\61";
}
@media only screen and (max-width: 480px) {
  div.datedropper.picker-lg {
    position: fixed;
    top: 50%!important;
    left: 50%!important;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    margin: 0;
  }
  div.datedropper.picker-lg:before {
    display: none;
  }
}
@-moz-keyframes picker_locked {
  0%,
  100% {
    -webkit-transform: translateX(-50%) translate3d(0, 0, 0);
    -moz-transform: translateX(-50%) translate3d(0, 0, 0);
    -ms-transform: translateX(-50%) translate3d(0, 0, 0);
    -o-transform: translateX(-50%) translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateX(-50%) translate3d(-2px, 0, 0);
    -moz-transform: translateX(-50%) translate3d(-2px, 0, 0);
    -ms-transform: translateX(-50%) translate3d(-2px, 0, 0);
    -o-transform: translateX(-50%) translate3d(-2px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translateX(-50%) translate3d(2px, 0, 0);
    -moz-transform: translateX(-50%) translate3d(2px, 0, 0);
    -ms-transform: translateX(-50%) translate3d(2px, 0, 0);
    -o-transform: translateX(-50%) translate3d(2px, 0, 0);
  }
}
@-webkit-keyframes picker_locked {
  0%,
  100% {
    -webkit-transform: translateX(-50%) translate3d(0, 0, 0);
    -moz-transform: translateX(-50%) translate3d(0, 0, 0);
    -ms-transform: translateX(-50%) translate3d(0, 0, 0);
    -o-transform: translateX(-50%) translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateX(-50%) translate3d(-2px, 0, 0);
    -moz-transform: translateX(-50%) translate3d(-2px, 0, 0);
    -ms-transform: translateX(-50%) translate3d(-2px, 0, 0);
    -o-transform: translateX(-50%) translate3d(-2px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translateX(-50%) translate3d(2px, 0, 0);
    -moz-transform: translateX(-50%) translate3d(2px, 0, 0);
    -ms-transform: translateX(-50%) translate3d(2px, 0, 0);
    -o-transform: translateX(-50%) translate3d(2px, 0, 0);
  }
}
@keyframes picker_locked {
  0%,
  100% {
    -webkit-transform: translateX(-50%) translate3d(0, 0, 0);
    -moz-transform: translateX(-50%) translate3d(0, 0, 0);
    -ms-transform: translateX(-50%) translate3d(0, 0, 0);
    -o-transform: translateX(-50%) translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateX(-50%) translate3d(-2px, 0, 0);
    -moz-transform: translateX(-50%) translate3d(-2px, 0, 0);
    -ms-transform: translateX(-50%) translate3d(-2px, 0, 0);
    -o-transform: translateX(-50%) translate3d(-2px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translateX(-50%) translate3d(2px, 0, 0);
    -moz-transform: translateX(-50%) translate3d(2px, 0, 0);
    -ms-transform: translateX(-50%) translate3d(2px, 0, 0);
    -o-transform: translateX(-50%) translate3d(2px, 0, 0);
  }
}
@-moz-keyframes picker_locked_large_mobile {
  0%,
  100% {
    -webkit-transform: translate(-50%, -50%) translate3d(0, 0, 0);
    -moz-transform: translate(-50%, -50%) translate3d(0, 0, 0);
    -ms-transform: translate(-50%, -50%) translate3d(0, 0, 0);
    -o-transform: translate(-50%, -50%) translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate(-50%, -50%) translate3d(-2px, 0, 0);
    -moz-transform: translate(-50%, -50%) translate3d(-2px, 0, 0);
    -ms-transform: translate(-50%, -50%) translate3d(-2px, 0, 0);
    -o-transform: translate(-50%, -50%) translate3d(-2px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate(-50%, -50%) translate3d(2px, 0, 0);
    -moz-transform: translate(-50%, -50%) translate3d(2px, 0, 0);
    -ms-transform: translate(-50%, -50%) translate3d(2px, 0, 0);
    -o-transform: translate(-50%, -50%) translate3d(2px, 0, 0);
  }
}
@-webkit-keyframes picker_locked_large_mobile {
  0%,
  100% {
    -webkit-transform: translate(-50%, -50%) translate3d(0, 0, 0);
    -moz-transform: translate(-50%, -50%) translate3d(0, 0, 0);
    -ms-transform: translate(-50%, -50%) translate3d(0, 0, 0);
    -o-transform: translate(-50%, -50%) translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate(-50%, -50%) translate3d(-2px, 0, 0);
    -moz-transform: translate(-50%, -50%) translate3d(-2px, 0, 0);
    -ms-transform: translate(-50%, -50%) translate3d(-2px, 0, 0);
    -o-transform: translate(-50%, -50%) translate3d(-2px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate(-50%, -50%) translate3d(2px, 0, 0);
    -moz-transform: translate(-50%, -50%) translate3d(2px, 0, 0);
    -ms-transform: translate(-50%, -50%) translate3d(2px, 0, 0);
    -o-transform: translate(-50%, -50%) translate3d(2px, 0, 0);
  }
}
@keyframes picker_locked_large_mobile {
  0%,
  100% {
    -webkit-transform: translate(-50%, -50%) translate3d(0, 0, 0);
    -moz-transform: translate(-50%, -50%) translate3d(0, 0, 0);
    -ms-transform: translate(-50%, -50%) translate3d(0, 0, 0);
    -o-transform: translate(-50%, -50%) translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate(-50%, -50%) translate3d(-2px, 0, 0);
    -moz-transform: translate(-50%, -50%) translate3d(-2px, 0, 0);
    -ms-transform: translate(-50%, -50%) translate3d(-2px, 0, 0);
    -o-transform: translate(-50%, -50%) translate3d(-2px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate(-50%, -50%) translate3d(2px, 0, 0);
    -moz-transform: translate(-50%, -50%) translate3d(2px, 0, 0);
    -ms-transform: translate(-50%, -50%) translate3d(2px, 0, 0);
    -o-transform: translate(-50%, -50%) translate3d(2px, 0, 0);
  }
}
div.datedropper.picker-rmbl {
  -webkit-animation: picker_locked 0.4s ease;
  -moz-animation: picker_locked 0.4s ease;
  animation: picker_locked 0.4s ease;
}
@media only screen and (max-width: 480px) {
  div.datedropper.picker-rmbl.picker-lg {
    -webkit-animation: picker_locked_large_mobile 0.4s ease;
    -moz-animation: picker_locked_large_mobile 0.4s ease;
    animation: picker_locked_large_mobile 0.4s ease;
  }
}
div.datedropper.picker-lkd .pick-submit {
  background-color: rgba(0, 0, 0, 0.04) !important;
  color: rgba(0, 0, 0, 0.2) !important;
}
div.datedropper.picker-lkd .pick-submit:hover {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
div.datedropper.picker-lkd .pick-submit:after {
  content: "\62" !important;
}
div.datedropper.picker-fxs {
  -webkit-transition: width 0.8s cubic-bezier(1, -0.55, 0.2, 1.37), opacity 0.2s ease, visibility 0.2s ease, margin 0.2s ease;
  -moz-transition: width 0.8s cubic-bezier(1, -0.55, 0.2, 1.37), opacity 0.2s ease, visibility 0.2s ease, margin 0.2s ease;
  -ms-transition: width 0.8s cubic-bezier(1, -0.55, 0.2, 1.37), opacity 0.2s ease, visibility 0.2s ease, margin 0.2s ease;
  -o-transition: width 0.8s cubic-bezier(1, -0.55, 0.2, 1.37), opacity 0.2s ease, visibility 0.2s ease, margin 0.2s ease;
}
div.datedropper.picker-fxs ul.pick.pick-d {
  -webkit-transition: top 0.8s cubic-bezier(1, -0.55, 0.2, 1.37), transform 0.8s cubic-bezier(1, -0.55, 0.2, 1.37), max-height 0.8s cubic-bezier(1, -0.55, 0.2, 1.37), background-color 0.4s ease;
  -moz-transition: top 0.8s cubic-bezier(1, -0.55, 0.2, 1.37), transform 0.8s cubic-bezier(1, -0.55, 0.2, 1.37), max-height 0.8s cubic-bezier(1, -0.55, 0.2, 1.37), background-color 0.4s ease;
  -ms-transition: top 0.8s cubic-bezier(1, -0.55, 0.2, 1.37), transform 0.8s cubic-bezier(1, -0.55, 0.2, 1.37), max-height 0.8s cubic-bezier(1, -0.55, 0.2, 1.37), background-color 0.4s ease;
  -o-transition: top 0.8s cubic-bezier(1, -0.55, 0.2, 1.37), transform 0.8s cubic-bezier(1, -0.55, 0.2, 1.37), max-height 0.8s cubic-bezier(1, -0.55, 0.2, 1.37), background-color 0.4s ease;
}
div.datedropper.picker-fxs ul.pick.pick-y {
  -webkit-transition: background-color 0.4s ease;
  -moz-transition: background-color 0.4s ease;
  -ms-transition: background-color 0.4s ease;
  -o-transition: background-color 0.4s ease;
}
div.datedropper.picker-fxs ul.pick li {
  -webkit-transition: transform 0.4s ease, opacity 0.4s ease;
  -moz-transition: transform 0.4s ease, opacity 0.4s ease;
  -ms-transition: transform 0.4s ease, opacity 0.4s ease;
  -o-transition: transform 0.4s ease, opacity 0.4s ease;
}
div.datedropper.picker-fxs ul.pick .pick-arw {
  -webkit-transition: transform 0.2s ease, opacity 0.2s ease;
  -moz-transition: transform 0.2s ease, opacity 0.2s ease;
  -ms-transition: transform 0.2s ease, opacity 0.2s ease;
  -o-transition: transform 0.2s ease, opacity 0.2s ease;
}
div.datedropper.picker-fxs ul.pick .pick-arw i {
  -webkit-transition: right 0.2s ease, left 0.2s ease;
  -moz-transition: right 0.2s ease, left 0.2s ease;
  -ms-transition: right 0.2s ease, left 0.2s ease;
  -o-transition: right 0.2s ease, left 0.2s ease;
}
div.datedropper.picker-fxs .pick-lg {
  -webkit-transition: max-height 0.8s cubic-bezier(1, -0.55, 0.2, 1.37);
  -moz-transition: max-height 0.8s cubic-bezier(1, -0.55, 0.2, 1.37);
  -ms-transition: max-height 0.8s cubic-bezier(1, -0.55, 0.2, 1.37);
  -o-transition: max-height 0.8s cubic-bezier(1, -0.55, 0.2, 1.37);
}
div.datedropper.picker-fxs .pick-lg .pick-lg-b li:before {
  -webkit-transition: transform 0.2s ease;
  -moz-transition: transform 0.2s ease;
  -ms-transition: transform 0.2s ease;
  -o-transition: transform 0.2s ease;
}
div.datedropper.picker-fxs .pick-btns .pick-submit {
  -webkit-transition: top 0.2s ease, box-shadow 0.4s ease, background-color 0.4s ease;
  -moz-transition: top 0.2s ease, box-shadow 0.4s ease, background-color 0.4s ease;
  -ms-transition: top 0.2s ease, box-shadow 0.4s ease, background-color 0.4s ease;
  -o-transition: top 0.2s ease, box-shadow 0.4s ease, background-color 0.4s ease;
}
div.datedropper.picker-fxs .pick-btns .pick-btn {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
@media only screen and (max-width: 480px) {
  div.datedropper.picker-fxs {
    -webkit-transition: opacity 0.2s ease, visibility 0.2s ease, margin 0.2s ease;
    -moz-transition: opacity 0.2s ease, visibility 0.2s ease, margin 0.2s ease;
    -ms-transition: opacity 0.2s ease, visibility 0.2s ease, margin 0.2s ease;
    -o-transition: opacity 0.2s ease, visibility 0.2s ease, margin 0.2s ease;
  }
  div.datedropper.picker-fxs ul.pick.pick-d,
  div.datedropper.picker-fxs .pick-lg {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
  }
}
div.datedropper.vanilla {
  border-radius: 6px;
  width: 180px;
}
div.datedropper.vanilla .picker {
  border-radius: 6px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
}
div.datedropper.vanilla .pick-l {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
div.datedropper.vanilla:before,
div.datedropper.vanilla .pick-submit,
div.datedropper.vanilla .pick-lg-b .pick-sl:before,
div.datedropper.vanilla .pick-m,
div.datedropper.vanilla .pick-lg-h {
  background-color: #feac92;
}
div.datedropper.vanilla .pick-y.pick-jump,
div.datedropper.vanilla .pick li span,
div.datedropper.vanilla .pick-lg-b .pick-wke,
div.datedropper.vanilla .pick-btn {
  color: #feac92;
}
div.datedropper.vanilla .picker,
div.datedropper.vanilla .pick-l {
  background-color: #ffffff;
}
div.datedropper.vanilla .picker,
div.datedropper.vanilla .pick-arw,
div.datedropper.vanilla .pick-l {
  color: #9ed7db;
}
div.datedropper.vanilla .pick-m,
div.datedropper.vanilla .pick-m .pick-arw,
div.datedropper.vanilla .pick-lg-h,
div.datedropper.vanilla .pick-lg-b .pick-sl,
div.datedropper.vanilla .pick-submit {
  color: #faf7f4;
}
div.datedropper.vanilla.picker-tiny:before,
div.datedropper.vanilla.picker-tiny .pick-m {
  background-color: #ffffff;
}
div.datedropper.vanilla.picker-tiny .pick-m,
div.datedropper.vanilla.picker-tiny .pick-m .pick-arw {
  color: #9ed7db;
}
div.datedropper.leaf {
  border-radius: 6px;
  width: 180px;
}
div.datedropper.leaf .picker {
  border-radius: 6px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
}
div.datedropper.leaf .pick-l {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
div.datedropper.leaf:before,
div.datedropper.leaf .pick-submit,
div.datedropper.leaf .pick-lg-b .pick-sl:before,
div.datedropper.leaf .pick-m,
div.datedropper.leaf .pick-lg-h {
  background-color: #1ecd80;
}
div.datedropper.leaf .pick-y.pick-jump,
div.datedropper.leaf .pick li span,
div.datedropper.leaf .pick-lg-b .pick-wke,
div.datedropper.leaf .pick-btn {
  color: #1ecd80;
}
div.datedropper.leaf .picker,
div.datedropper.leaf .pick-l {
  background-color: #fefff2;
}
div.datedropper.leaf .picker,
div.datedropper.leaf .pick-arw,
div.datedropper.leaf .pick-l {
  color: #528971;
}
div.datedropper.leaf .pick-m,
div.datedropper.leaf .pick-m .pick-arw,
div.datedropper.leaf .pick-lg-h,
div.datedropper.leaf .pick-lg-b .pick-sl,
div.datedropper.leaf .pick-submit {
  color: #fefff2;
}
div.datedropper.leaf.picker-tiny:before,
div.datedropper.leaf.picker-tiny .pick-m {
  background-color: #fefff2;
}
div.datedropper.leaf.picker-tiny .pick-m,
div.datedropper.leaf.picker-tiny .pick-m .pick-arw {
  color: #528971;
}
div.datedropper.primary {
  border-radius: 6px;
  width: 180px;
}
div.datedropper.primary .picker {
  border-radius: 6px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
}
div.datedropper.primary .pick-l {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
div.datedropper.primary:before,
div.datedropper.primary .pick-submit,
div.datedropper.primary .pick-lg-b .pick-sl:before,
div.datedropper.primary .pick-m,
div.datedropper.primary .pick-lg-h {
  background-color: #00c0ef;
}
div.datedropper.primary .pick-y.pick-jump,
div.datedropper.primary .pick li span,
div.datedropper.primary .pick-lg-b .pick-wke,
div.datedropper.primary .pick-btn {
  color: #00c0ef;
}
div.datedropper.primary .picker,
div.datedropper.primary .pick-l {
  background-color: #ffffff;
}
div.datedropper.primary .picker,
div.datedropper.primary .pick-arw,
div.datedropper.primary .pick-l {
  color: #4d4d4d;
}
div.datedropper.primary .pick-m,
div.datedropper.primary .pick-m .pick-arw,
div.datedropper.primary .pick-lg-h,
div.datedropper.primary .pick-lg-b .pick-sl,
div.datedropper.primary .pick-submit {
  color: #ffffff;
}
div.datedropper.primary.picker-tiny:before,
div.datedropper.primary.picker-tiny .pick-m {
  background-color: #ffffff;
}
div.datedropper.primary.picker-tiny .pick-m,
div.datedropper.primary.picker-tiny .pick-m .pick-arw {
  color: #4d4d4d;
}
div.datedropper .null {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
}

/*=================================================
=            PERSONALIZADO PARA DENTAL            =
=================================================*/
div.datedropper{
  z-index: 50;
}

div.datedropper .picker ul.pick.pick-m{
  font-size: 25px;
}

div.datedropper .picker ul.pick.pick-m{
  height: 40px;
  line-height: 40px;
}

div.datedropper .picker ul.pick.pick-d{
  font-size: 38px;
  line-height: 40px;
  height: 60px;
}

div.datedropper .picker ul.pick li span{
  bottom: 10px;
}

div.datedropper .picker ul.pick.pick-y{
  font-size: 20px;
  height: 45px;
  line-height: 45px;
}
/*=====  End of PERSONALIZADO PARA DENTAL  ======*/