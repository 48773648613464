/* frozzen columnas y filas*/
.tabla_fixed {
    width: 600px;
    height: 400px;
    /*overflow: scroll;*/
    overflow: auto;
    border: 1px solid #777777;
    /*background-color: white;*/
}

.tabla_fixed table {
    border-spacing: 0;
}

.tabla_fixed th {
    border-left: none;
    border-right: 1px solid #bbbbbb;
    padding: 5px;
    width: 80px;
    min-width: 80px;
    position: sticky;
    top: 0;
    /*background: #727272;*/
    /*color: #e0e0e0;*/
    font-weight: normal;
}

.tabla_fixed td {
    border-left: none;
    /*border-right: 1px solid #bbbbbb;
    border-bottom: 1px solid #bbbbbb;*/
    padding: 5px;
    width: 80px;
    min-width: 80px;
    background-color: white;
}

.tabla_fixed th:nth-child(1),
.tabla_fixed td:nth-child(1) {
    position: sticky;
    left: 0;
    /*width: 150px;
    min-width: 150px;*/
}
/*------------ una columna fixed ------------*/
/*.tabla_fixed th:nth-child(2),
.tabla_fixed td:nth-child(2) {
    position: sticky;
    /* 1st cell left/right padding + 1st cell width + 1st cell left/right border width */
    /* 0 + 5 + 150 + 5 + 1 */
/*    left: 150px; /*antes 161*/
/*    width: 50px;
    min-width: 50px;
}*/

.tabla_fixed td:nth-child(1)
{
    background: #ffebb5;
}

.tabla_fixed th:nth-child(1)
{
    z-index: 2;
}
/**************/
.tabla_fixed_borde tbody td:hover
{
    background-color: #f2f2f2;
    cursor: pointer;
}
/**********************/

.tabla_fixed_borde tbody td, 
.tabla_fixed_borde thead th {
    border-left: 1px dotted #515151;
    border-top: 1px dotted #515151;
    padding: 4px;
    text-align: left;
}
/**************** original 2 columnas fixed***********************************/
/*.tabla_fixed th:nth-child(2),
.tabla_fixed td:nth-child(2) {
    position: sticky;
    /* 1st cell left/right padding + 1st cell width + 1st cell left/right border width */
    /* 0 + 5 + 150 + 5 + 1 */
/*    left: 150px; /*antes 161*/
/*    width: 50px;
    min-width: 50px;
}

.tabla_fixed td:nth-child(1),
.tabla_fixed td:nth-child(2) {
    background: #ffebb5;
}

.tabla_fixed th:nth-child(1),
.tabla_fixed th:nth-child(2) {
    z-index: 2;
}
*/

table .cell_azul {
    background-color: #0074cc;
    color: white;
    text-align: center !important;
    vertical-align: middle;
}
/************** calendar ************************/
table tbody td.e-selected-cell {
    background-color: #b7e0ff;
    color: #333;
}

table tbody td.e-grey-cell {
    background-color: #ececec;
    /*color: #282828;*/
}

table .e-work-cells {
    background-color: #f8f8f8;
    border-color: #d0d0d0;
    border-style: solid;
    border-width: 0 0 1px;
      border-left-width: 0px;
    color: #333;
    padding: 5px;
}
/**********para el child event ***********/
.cal-event {
  font-size: 0.85em;
  line-height: 1.4;
}
.cal-event, .cal-event:hover {
  color: #fff;
}
.cal-day-box-event {
  margin: 1px 2px 0;
  padding: 0 1px;
}
.cal-day-box-event .fc-content {
  white-space: nowrap;
}
.cal-day-box-event .fc-time {
  font-weight: bold;
}

/*para el contenido*/
.cal-day-box-event .cal-content {
  white-space: nowrap;
}

.cal-event {
  position: relative;
  display: block;
  font-size: 0.85em;
  line-height: 1.4;
  border-radius: 3px;
  border: 1px solid #3788d8;
}
.cal-event, .cal-event-dot {
  background-color: #3788d8;
}
.cal-event .cal-content {
  position: relative;
  z-index: 2;
}
.cal-day-box-event .cal-time {
  font-weight: bold;
}
