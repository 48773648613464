/*table .cell_azul {
  background-color: #0074cc;
  color: white;
  text-align: center;
  vertical-align: middle;
}*/
.tabla_fixed .selected {
  background-color: #f9f6f6;
  border: 1px solid rgb(81, 92, 230);
}

.nucellm{
  height: 35px;
  width: 100%;
}
.cel-plomo{
  background-color: #eceaea  !important;
}
.b-button{
  position: relative;
  display: block;
  font-size: 0.85em;
  line-height: 1.4;
  border-radius: 3px;
  border: 1px solid #4d545c;
  text-transform: uppercase;
}
.b-default{
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
}
.b-primary{
  background-color: #3788d8;
  border: 1px solid #3788d8;
  color: #fff;
}
.b-warning{
  background-color: #feb019;
  border: 1px solid #feb019; /* #79540c;*/
  color: #333;;
}
.b-danger{
  background-color: #f9b4be;
  border: 1px solid #f9b4be; /* #82081a;*/
  color: black;;
}
.b-success{
  background-color: #98e1c8;
  border: 1px solid #98e1c8; /* #268867;*/
  color: black;;
}

