
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  /*.fade {
    opacity: 0;
    -webkit-transition: opacity .15s linear;
    -o-transition: opacity .15s linear;
    transition: opacity .15s linear;
  }
  .fade.in {
    opacity: 1;
  }*/
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }
/**************************/
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}
.modal-backdrop.in {
filter: alpha(opacity=50);
opacity: .5;
}
/*************************/
.modal-dialog{
    pointer-events: auto !important;
}
.modal-content1 {
    -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
}
.modal-content1 {
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
}
.modal-header1 {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;

    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
/*-----------------------------*/
.bg-primary1 {  color: #fff; background-color: #337ab7; }

.bg-danger1 {  background-color: #f2dede; }
.text-danger1 { color: #a94442; }

.bg-success1 { background-color: #dff0d8; }
.text-success1 { color: #3c763d; }

.bg-warning1 {  background-color: #fcf8e3; }
.bg-info1 {   background-color: #d9edf7; }

.bg-default1{
  background: #f6f7f9 0 0 repeat-x;
  background: -moz-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f6f7f9), color-stop(100%, #ebedf0));
  background: -webkit-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  background: -o-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  background: -ms-linear-gradient(top, #f6f7f9 0%, #ebedf0 100%);
  background: linear-gradient(to bottom, #f6f7f9 0%, #ebedf0 100%);
}
.text-default1{
  color: #1b1d1f;
  font-weight: normal;
}
.modal-header1 .close {
    /*margin-top: -2px;*/
    margin: 0;
  }
  button.close {
    -webkit-appearance: none;
    padding: 0;
    
    cursor: pointer;
    background: 0 0;
    border: 0;
  }
  .close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
  }
  .close:focus, .close:hover {
    color: #000;
    cursor: pointer;
  }

  .modal-title {
    margin: 0;
    line-height: 1.42857143;
  }

  .modal-body1 {
    position: relative;
    padding: 15px;
  }

  .modal-body1 .fa {
    font-size: 1.5em;
    vertical-align: middle;
    margin-right: .5em;
  }

  .modal-footer1 {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
  }
  /***** animacion***************/
  .modal.in .modal-dialog {
    -webkit-transform: translate(0,0);
    -ms-transform: translate(0,0);
    -o-transform: translate(0,0);
    transform: translate(0,0);
  }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: transform .3s ease-out;
    /*-webkit-transform: translate(0,-25%);
    -ms-transform: translate(0,-25%);
    -o-transform: translate(0,-25%);
    transform: translate(0,-25%);*/
    
    transform: translateY(5%);
  }