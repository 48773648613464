


.tituloVerde {
    background-color: #e2e0cb;
    border-bottom: 2px solid #6d4c30;
    border-top: 1px solid #c0c0c0;
    border-left: 1px solid #c0c0c0;
    border-right: 1px solid #c0c0c0;
    color: #64462c;
    height: 21px;
    line-height: 1.2em;
    padding: 4px 15px 4px !important;
    font-weight: bold;
}

.tituloAzul{
    background-color: #e2e0cb;
    border-bottom: 2px solid #6d4c30;
    border-top: 1px solid #c0c0c0;
    border-left: 1px solid #c0c0c0;
    border-right: 1px solid #c0c0c0;
    color: #64462c;
    height: 21px;
    line-height: 1.2em;
    padding: 4px 15px 4px !important;
    font-weight: bold;
}
.bg_azul1{ background-color: #1e3b72 !important;}

.bg_celeste{ background-color: #e4ecf7; }
.bg_celeste1{ background: #4a90e2; }
.bg_celeste2{ background: #09f; }
.bg_celeste3{ background-color: #A6BAD0;}

.bg_naranja{ background: #fbd05b; }
.bg_naranja1{ background-color: #f25c26;}

.bg_verde{ background-color: #4dbfbf; }
.bg_verde1{ background-color: #03717f; }

.bg_plomoform{ background-color: #f9f9f9;}
.bg_plomo1{ background-color: #f0f0f0;}
.bg_plomo2{ background-color: #343e47;}
.bg_plomo3{ background-color: #F4F4F4;} /*para form*/
.bg_plomo4{ background-color: #707071 !important;}

.bg_naranjad{
    background: linear-gradient(to bottom, #ffcb41 0px, #ffb721 100%) repeat-x scroll 0 0 rgba(0, 0, 0, 0);
}
.bg_plomo{
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-left-color: rgb(204, 204, 204);
    border-left-style: solid;
    border-left-width: 1px;
}
.bg_plomo1{
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-left-color: rgb(204, 204, 204);
    border-left-style: solid;
    border-left-width: 1px;
    border-left: 5px solid #09f;
    border-radius: 4px;
}



.celda-blue {
    margin: 0 0 16px;
    padding: 0 9px;
    color: #000 !important;
    color: #117ace !important;
    background-color: #ddffff !important;
    border-left: 6px solid #ccc !important;
    border-bottom: 2px solid #ccc !important;
    border-bottom-color: rgb(204, 204, 204);
    border-color: #2196F3 !important;
}

.celda-celeste {
    color: #23547f;
    background-color: #bed2db !important;
    border-bottom: 2px solid #23547f !important;
}

.celda-cafe1 {
    color: #8a4e12;
    border-bottom: 2px solid #bd7100 !important;
    padding: 3px;
    font-weight: bold;
}

.celta {
    background-color: #f3f4f6;
}
.separador_celeste {
    background: none repeat scroll 0% 0% rgb(218, 232, 245);
}
/* para la nota*/
.note {
	/*font-family: verdana, arial, sans-serif;*/
	font-size: 14px;
	color: #252525; /*obscuro*/
	border-bottom: 1px #5DA3E5 solid; /*lineaazulito*/
	padding: 3px 10px 2px 22px;
	margin: 8px 0 8px 0;
	background: url(../assets/img/info.gif) #F1F8FF no-repeat 3px 2px;
	text-align:left;
}
.notecafe {
	/*font-family: verdana, arial, sans-serif;*/
	font-size: 14px;
	color: #252525; /*obscuro*/
	border-bottom: 1px #CCC solid; /*lineaazulito*/
	padding: 3px 10px 2px 22px;
	margin: 8px 0 8px 0;
	background: url(../assets/img/info.gif) #F1F8FF no-repeat 3px 2px;
	text-align:left;
}
/* este es el amarillo bajito fondo color*/
.infocafe {
	background: url(../assets/img/info.gif) #FFC no-repeat 3px 2px;
	padding-left:30;

}

.gen-note {
	font-family: verdana, arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	color: #252525;
	border-bottom: 1px #5DA3E5 solid;
	padding: 8px 10px 8px 30px;
	margin: 8px 0 8px 0;
	text-align:left;
}

.gen-note h4{
    margin: 0;
    padding-bottom:5px;
}

.GeneralNote {
	font-family: verdana, arial, sans-serif;
	font-size: 11px;
	color: #252525;
	border-bottom: 1px #5DA3E5 solid;
	padding: 3px 10px 2px 22px;
	margin: 8px 0 8px 0;
	background: #F1F8FF;
	text-align:left;
}
.card-ex3 {
    background: none repeat scroll 0 0 #fff;
    border: 1px solid #ccc;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    margin: 10px auto;
    padding: 20px;
    width: 790px;
}
.plomito2 {
    background: none repeat scroll 0 0 #f2f6f6;
    margin-bottom: 20px;
    width: 100%;
}
/* para ventas y compras------------------------------------------*/
.fieldset_plomo {
    border: 1px solid #ffffff;
    border: 1px solid #d5dfe5;
    border-radius: 4px;
    /*background: none repeat scroll 0 0 #F2F2F2;*/
    display: inline;
    float: left;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 3px 3px;
    /*position: relative;
    width: 350px;*/
}
.fieldset_plomo legend {
    width: auto;
    padding: 0 10px;
    border-bottom: none;
    font-size: 13px !important;
   
    text-align: left !important;
    margin-bottom: 0;
    border-radius: 5px;
    color:#2d2c2c;
}
.panel120 {
    float: left;
    /*min-height: 125px;*/
}
/**************************************************************/
.fieldsetx {
    margin-top: 1em;
    margin-bottom: 1em;
    padding: .5em;
}

.fieldsetx legend {
    color: blue;
    font-weight: bold;
    font-size: 85%;
    margin-bottom: .5em;
}

.fieldsetx label {
    float: left;
    font-weight: normal !important;
}
/**************************************************/
/******************otro fieldset ***************/
fieldset.nufieldset-border {
    border: 1px solid #d1d1d1 !important;
    padding: 20px 10px 5px 10px !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
    border-radius: 5px;
    box-shadow: 0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2);
    background: linear-gradient( 90deg, #fefefe, #f0f0f0);
}
legend.nulegend-border {
    font-size: 1em !important;
    font-weight: bold !important;
    text-align: left !important;
    width: auto;
    padding: 0 10px;
    border-bottom: none;
}
/******fielset azulito********************/
.fieldset_azul {
    border-right: #d0d7e5 1px solid;
    border-bottom: #d0d7e5 1px solid;
    padding: 1px;
    padding-right: 2px;
    padding-left: 5px;
    padding-bottom: 2px;
    padding-top: 3px;
    border-left: #d0d7e5 1px solid;
    background-color: #e4ecf7;
    border: #98bcdf 1px solid;
}
fieldset.fieldset_azul legend{
    width: auto;
    padding: 0 10px;
    color: black;
    border: 0px;
    background-color: transparent;
}
/**-------con recuadrito */
/*****************************/
fieldset.box-border 
{
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 0 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
    border-radius: 5px;
}

fieldset.box-border legend {
    width: auto;
padding: 0 10px;
/*border-bottom: none;*/
font-size: 12px !important;
font-weight: bold !important;
text-align: left !important;
border: 1px solid #ccc;
border-radius: 5px;
color: #555;
background-color: #e7e7e7;

}
/*para dialog ventas, compras*/
.form_degrade_azul {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #a5c3e7;
    background-image: -moz-linear-gradient(center top , #a5c3e7, #82a6e2);
    border-color: #a3c2f8 #a3c2f8 #a3c2f8 #a3c2f8;
    border-image: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 3px;
    color: #060606;
    padding: 8px 0;
    /*text-align: center;*/
    color: #000; 
}

.form_degrade_celeste {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #fefefe;
    background-image: -moz-linear-gradient(center top , #fefefe, #d3ebfa);
    border-color: #2a436c #2a436c #2a436c #2a436c;
    border-image: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    color: #060606;
    padding: 8px 0;
    /*text-align: center;*/
    color: #000;
}
.botonFlat {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #EEEEEE;
    background-image: -moz-linear-gradient(center top , #EEEEEE, #CCCCCC);
    border-color: #545454 #545454 #545454 #545454;
    border-image: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    color: #060606;
    font: normal 12px/1 Verdana,"Lucida Sans Unicode","Lucida Sans",Geneva,sans-serif;
    padding: 8px;
    text-align: center;
    text-shadow: 0 1px 0 #EEEEEE;
    /*width: 150px;*/
    cursor: pointer;
}
.botonFlat:hover {
    background-color: #f37873;
    background-image: -moz-linear-gradient(center top , #f37873 0%, #db504d 50%, #cb0500 50%, #a20601 100%);
    border: 1px solid #951100;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(255, 115, 100, 0.4) inset, 0 1px 3px #333333;
    color: #FFFFFF;
    border-image: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    color: #ffffff;
    font: normal 12px/1 Verdana,"Lucida Sans Unicode","Lucida Sans",Geneva,sans-serif;
    padding: 8px;
    text-align: center;
    
}
.div_azulAma {
    background-color: #0066cc;
    line-height: 18px;
    color: #FF0;
    margin: 0;
    overflow: hidden;
    padding: 0 4px;
    white-space: nowrap;
    word-wrap: normal;
}
.H3_amarillo {
    FONT: normal 20px Verdana;
        line-height: normal;
    color: #FF0;
    TEXT-DECORATION: none;
}
.H2_titulo{
    font-size: 24px;
    font-weight: bold;
    /*font-style: italic;*/
    COLOR: #114477;
    
}
/*sombreado*/
.border-white{
    /*border white with light shadow*/
    text-shadow:
    1px 1px #fff,
    -1px 1px #fff,
    1px -1px #fff,
    -1px -1px #fff,
    1px 1px 5px #555;
    }