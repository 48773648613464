.side-menu h3{
  position: relative;
  border-top: 1px solid #dfe1e2;
  line-height: normal;
  color: #1A6986;
  margin: 0;
  padding: 0.5rem 1rem;
  background-color: transparent;
}
.side-menu h3::before {
  background-color: #1A6986;
  border-radius: 99rem;
  content: '';
  display: block;
  position: absolute;
  left: .05rem;
  top: 0.2rem;
  width: 0.25rem;
  height: 90%;
}

/*contenedor*/
.side-menu ul.nu-sidenav {
  /*font-size: 1.06rem;*/
  list-style: none;
  margin: 0;
  padding: 0;
}
.nu-sidenav > li {
  margin-bottom: 0;
  max-width: unset;
}
.nu-sidenav_item {
  border-top: 1px solid #dfe1e2;
}
.side-menu .menu-item--active > div {
  color: #1A6986 !important;
  font-weight: bold;
}

/*  ITEM */
.side-menu ul.nu-sidenav li.nu-sidenav_item div {
  cursor: pointer;
  display: block;
  padding: .5rem 1rem;
}

.side-menu ul.nu-sidenav li.nu-sidenav_item div:hover {
  color: #1A6986 !important;
  background-color: #f0f0f0;
}
/* ITEM ACTIVE */
.side-menu .menu-item--active > div {
  color: #1A6986 !important;
  font-weight: bold;
}