@charset "utf-8";
/* CSS Document */


/********************************/
table.tabla_plomo {
    border: 1px solid #CCCCBB;
    border-collapse: collapse;
	BACKGROUND-COLOR: #fafafa;
   /* width: 95%;*/
	/*color:333333;*/
}

table.color_plomo{
	color:333333;
}

table.tabla_plomo tr {
    /*border-bottom: 1px solid #DDDDDD;*/
	
}
table.tabla_plomo > tbody tr:hover td { /*este uttimo td es porque no permitia ver elnaranja*/
    /*background: #fcaf3e;*//*naranja*/
    /*color: #FFFFFF;*/ /*blanco*/
	background: rgb(230, 230, 230);
	
}

/*even and odd*******************************/
/*balnco claro*/
/*table.tabla_plomo tr:nth-child(odd) td {
    background-color: #f8f8f8; 
} 
*/
/*celeste obs*/
/*
table.tabla_plomo tr:nth-child(even) td {
    background-color: #eeeeee; 
} */

/*si encima de las etiquetas a colocar texto blancoi*/
table.tabla_plomo > tbody tr:hover a {
    color: #FFFFFF;
}
/** este son los cabeceras ************************/
table.tabla_plomo th {
	/*border-top:1px solid #d3d3c7; 
	border-left:1px solid #d3d3c7;
	border-right:1px solid #c6c4b8; 
	border-bottom:1px solid #c6c4b8; 
	
	background-color:#444;
	 color: #eff4f4;
	 font-size: 13px;
	    padding: 5px ;
    text-align: left;
    white-space: nowrap;*/
	/* text-transform: uppercase;*/
	
	
	background-color: #d7d7d8;
	
	border-bottom: 2px solid #7b7b7b;
    border-top: 1px solid #c0c0c0;
    border-left: 1px solid #c0c0c0;
    border-right: 1px solid #c0c0c0;
	
    /*border-color: #cbcbcb;*/
    /*border-style: solid;*/
    /*border-width: 1px;*/
    color: #404040;
    height: 0;
   
    margin: 8px 0;
    
    text-decoration: none;
	
    /* height: 21px; */
    line-height: 1.2em;
    padding: 4px 15px 4px;
	text-align: left;
}

table.tabla_plomo td {
   /* border: 1px solid #E0E0E0;*/
   /*top,,button,rigth */
    padding: 5px 7px 5px 7px; /*antes 5*/
    /*border-left: 1px solid #c2bab0;*/
	border-bottom: 1px solid #c2bab0;
	
}