.dropIcon {
    
    width: 100%;
    
    /*background-color: white;*/
    
}

.dropIcon button.dropdown-toggle{
  /*width: 100%;*/
  background-color: #eaeaea;
}
.dropIcon button:active{
  color: #333333;
}
  