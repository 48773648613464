@charset "utf-8";

h2{
	margin:0;
	padding:0;
}


/*-----------para el table plomito----*/
.plomito {
    background: none repeat scroll 0 0 #f4f4f4;
    border-bottom: 1px solid #ccc;
   /* width: 100%;*/
/*    z-index: 1;*/
}
.plomito2{
    background: none repeat scroll 0 0 #f2f6f6;
    /*margin-bottom: 20px;*/
    width: 100%;
}

/********************************/
table.etigridb2 {
    border: 1px solid #CCCCBB;
    border-collapse: collapse;
	BACKGROUND-COLOR: #fafafa;
   /* width: 95%;*/
}
table.etigridb2 tr {
    border-bottom: 1px solid #DDDDDD;
	/*background-color:#FFF;/*color de fondo blanco a todos los rows*/
}
table.etigridb2 > tbody tr:hover td { /*este uttimo td es porque no permitia ver elnaranja*/
    background: #ffd496;/*naranja #feecd8*/
    /*color: #FFFFFF; blanco*/
}

/*even and odd*******************************/
table.etigridb2 tr:nth-child(odd) td {
    background-color: #f8f8f8; /*balnco claro*/
} 
table.etigridb2 tr:nth-child(even) td {
    background-color: #eeeeee; /*celeste obs*/
}  /**/

/*si encima de las etiquetas a colocar texto blancoi*/
table.etigridb2 > tbody tr:hover a {
    color: #FFFFFF;
}
/** este son los cabeceras ************************/
table.etigridb2 th {
	border-top:1px solid #d3d3c7; 
	border-left:1px solid #d3d3c7;
	border-right:1px solid #c6c4b8; 
	border-bottom:1px solid #c6c4b8; 
	
    background: linear-gradient(to bottom, #eceadd 0px, #c8c5bc 100%) repeat-x scroll 0 0 rgba(0, 0, 0, 0);

	background-color:#444;
	 color: #3e3e3e;
	 font-size: 13px;
    padding: 5px ;
    text-align: left;
    white-space: nowrap;
    text-transform: uppercase;
}

table.etigridb2 td {
   /* border: 1px solid #E0E0E0;*/
   /*top,,button,rigth */
    padding: 5px 7px 5px 7px; /*antes 5*/
    border-bottom: 1px solid #f0f0f0;
	
}

/**************************************************/
/*para el paginador*/
.btn_separator {
    border-left: 1px solid #ccc;
    border-right: 1px solid #fff;
    float: left;
    height: 22px;
    margin: 1px;
}
/* este es debrade de contenedor menu*/
.degradePlomo {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: url("../img/dg_bg.gif") repeat-x scroll 0 -1px rgba(0, 0, 0, 0);
    border-color: -moz-use-text-color #ccc #ccc;
    border-image: none;
    border-right: 1px solid #ccc;
    border-style: none solid solid;
    border-width: 0 1px 1px;
    /*overflow: hidden;*/
/*    position: relative;*/
    white-space: nowrap;
	color: #4d4d4d;
    height: 20px;
}

/*********************************************/
table.tablax th {
border-top: 1px solid #d7d7d7;
border-left: 1px solid #ccc;
border-right: 1px solid #ccc;
border-bottom: 1px solid #ccc;
background-color: #444;
color: #3c4242;
font-size: 13px;
text-transform: uppercase;
background: linear-gradient(to bottom, #f5f5f5 0px, #bababa 100%) repeat-x scroll 0 0 rgba(0, 0, 0, 0);
padding: 5px;
text-align: left;
white-space: nowrap;
}
table.tablax tfoot td{
	background-color: #CCC;
}

/*********************************************/
table.tablap th {
/*background: url(../img2/gradiente_nar.gif) #FF9900 repeat-x;*/
background-color: #444;
color: #3c4242;

/*background: linear-gradient(to bottom, #f5f5f5 0px, #bababa 100%) repeat-x scroll 0 0 rgba(0, 0, 0, 0);*/
	
background: linear-gradient(to bottom, #ffcb41 0px, #ffb721 100%) repeat-x scroll 0 0 rgba(0, 0, 0, 0);
	
/*padding: 5px;
text-align: left;*/
white-space: nowrap;
}
table.tablap td, table.tablap th {
    /*border: 1px solid #ddd;*/
    /*text-align: left;*/
    padding: 3px;
}

table.tablap tfoot td{
	background-color: #CCC;
}

/*************** tabla modelo basico ******************************/
table.tabla_basic {
    width: 100%;
    border-collapse: collapse;
	/*BACKGROUND-COLOR: #fff;*/
}
table.tabla_basic th {
    /*color: #3c4242;*/
    padding: 5px;
    text-align: left;
    white-space: nowrap;
}
table.tabla_basic td {
    padding: 3px 7px 3px 7px;
}
table.tabla_basic tfoot td{
    background-color: #CCC;
}
/***************************************/
