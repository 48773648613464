
.nu-icon {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: FontAwesome;
    font-size: var(--ag-icon-size);
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: var(--ag-icon-size);
    text-transform: none;
}
[class^="nu-"]::before{
    box-sizing: border-box;
    outline: none;
}
.nu-icon-desc::before { /*down*/
    opacity: .6;
    content: "\f063";
    
}
.nu-icon-asc::before {  /*up*/
    opacity: .6;
    content: "\f062";
    transform: rotate(180deg)
}

/*espacio entre la letra y el icon*/
.nu-sort-indicator-icon {
    padding-left: 5px;
}

.nu-header-cell-sortable {
    cursor: pointer;
}
.nu-cell-label-container {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    justify-content: space-between;
    overflow: hidden;
    padding: 5px 0;
    width: 100%;
}
/**************/
.pagination {
    padding-left: 0;
    /*margin-top: 1rem;*/
}
.pagination > li {
    display: inline;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}
.pagination > li > a:focus, 
.pagination > li > a:hover,
.pagination > li > span:focus, 
.pagination > li > span:hover {
    color: #014c8c;
    background-color: #eceeef;
    border-color: #c4c9d0;
  }
.pagination > li > a, 
.pagination > li > span {
    position: relative;
    float: left;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.5;
    color: #0275d8;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #c4c9d0;
}
.pagination > .active > a,
.pagination > .active > a:focus, 
.pagination > .active > a:hover, 
.pagination > .active > span, 
.pagination > .active > span:focus, 
.pagination > .active > span:hover {
    z-index: 2;
    color: #fff;
    cursor: default;
    background-color: #0275d8;
    border-color: #0275d8;
}


.pagination > li > a.disabled,
.pagination > li > span.disabled
{
    border: 1px solid #c4c9d0;
    background-color: #fff;
    color: #8a93a2;
    pointer-events: none;
}

[role="button"] {
    cursor: pointer;
}

