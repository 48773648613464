.file_manager .file {
  position: relative;
}
.file_manager .file:hover {
  box-shadow: 0 0 3px rgba(0,0,0,0.1),0 6px 20px rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
}
.colorNar{
  color: #f96332;
  text-decoration: none;
  background-color: transparent;
}
.card {
  background: #fff;
  transition: .5s;
  border: 0;
  border-radius: .1875rem;
  position: relative;
  width: 100%;
  box-shadow: none;
}
.file_manager .file .hover {
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
  transition: all 0.2s ease-in-out;
}
/*para que aparesca el boton*/
.file_manager .file a:hover .hover {
  display: block;
  transition: all 0.2s ease-in-out;
}
.file_manager .file .hover {
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
  transition: all 0.2s ease-in-out;
}
.file_manager .file .image, 
.file_manager .file .icon {
  height: 150px;
  overflow: hidden;
  background-size: cover;
  background-position: top;
}
.file_manager .file .file-name {
  padding: 10px;
  border-top: 1px solid #eceff1;
}
.m-b-5, .weather .days li img {
  margin-bottom: 5px;
}
.file_manager .file .file-name small {
  display: block;
}
.file_manager .file .file-name small .date {
  float: right;
}
/*boton para borrar*/
.btn-round {
  border-width: 1px;
  border-radius: 30px !important;
  padding: 11px 23px;
}
.btn-danger {
  background-color: #FF3636;
  color: #fff;
}
.zmdi-delete::before {
  content: '\f1f8';
}
