a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.form-select {
  font: inherit;
}

/*************/
.auth-layout-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  background: url(../assets/img/fondo01.jpg);
}
.auth-layout-wrap .auth-content {
  min-width: 340px;
  margin: auto;
}
.auth-logo img {
  width: 100px;
  height: 100px;
}
.form-control.form-control-rounded, .form-control.rounded {
  border-radius: 20px;
}
.btn.btn-rounded, .btn.rounded {
  border-radius: 40px !important;
}
.btn-block {
  display: block;
  width: 100%;
}

.signup-form {
  width: 450px;
  margin: 0 auto;
  padding: 30px 0;
  font-size: 15px;
}
.signup-form form {
  color: #999;
  border-radius: 3px;
  margin-bottom: 15px;
  background: #f2f3f7;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  padding: 30px;
}
.signup-form h2 {
  color: #636363;
  margin: 0 0 15px;
  position: relative;
  text-align: center;
}
.signup-form h2::before, 
.signup-form h2::after {
  content: "";
  height: 2px;
  width: 30%;
  background: #d4d4d4;
  position: absolute;
  top: 50%;
  z-index: 2;
}
.signup-form h2::before {
  left: 0;
}
.signup-form h2::after {
  right: 0;
}
.hint-text {
  color: #999;
  margin-bottom: 30px;
  text-align: center;
}
.signup-form .form-group {
  margin-bottom: 20px;
}
.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
/************ correcciones *****/
.form-select {
  height : 32px;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
/*************************************/
hr {
  margin-top: 10;
  margin-bottom: 10;
  border-top: 2px solid rgba(0, 0, 0, 0.11);
}
/*label:not(.form-check-label):not(.custom-file-label) {
  font-weight:normal !important;
}*/
.list-group-item {
  padding: .5rem 1.25rem;
}
.pull-right {
  float: right !important;
}
dl, ol, ul {
  margin-top: 0;
  margin-bottom: 0;
}
.breadcrumb {
  margin-bottom: 0;
}
.class_red{
  color:red;
}
.form-control {
/*height: calc(2.05rem + 2px);*/
 display: block;
width: 100%;
height: 32px;
padding: 6px 12px;
/*font-size: 14px;*/
line-height: 1.42857143;
color: #555;
background-color: #fff;
background-image: none;
border: 1px solid #ccc;
border-radius: 4px;
-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
-webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
-o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.alert {
  position: relative;
  padding: .5rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
  border-radius: .25rem;
}
.alert-primary {
  color: #004085 !important;
  background-color: #cce5ff !important;
  border-color: #b8daff;
}
.alert-secondary .alert-link {
  color: #202326 !important;
}
.alert-danger {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb;
}
.alert-success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb;
}
.alert-dark {
  color: #1b1e21 !important;
  background-color: #d6d8d9 !important;
  border-color: #c6c8ca;
}
.alert-info {
  color: #0c5460 !important;
  background-color: #d1ecf1 !important;
  border-color: #bee5eb;
}
.alert-warning {
  color: #856404 !important;
  background-color: #fff3cd !important;
  border-color: #ffeeba;
}
/***** botones*************/
/**********************************/
.btn {
  display: inline-block;
  /**display: inline;
  *zoom: 1;*/
  /*padding: 4px 10px 4px;*/
  margin-bottom: 0;
  /*font-size: 14px;*/
  line-height: 18px;
  color: #333333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -ms-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(top, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e6e6e6', GradientType=0);
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
  border: 1px solid #cccccc;
  border-bottom-color: #b3b3b3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  /**margin-left: .3em;*/
}
.btn:hover {
  color: #333333;
  text-decoration: none;
  background-color: #e6e6e6;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -ms-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}
.btn-primary {
  background-color: #0074cc;
  background-image: -moz-linear-gradient(top, #0088cc, #0055cc);
  background-image: -ms-linear-gradient(top, #0088cc, #0055cc);
  background-image: -webkit-linear-gradient(top, #0088cc, #0055cc);
  background-image: -o-linear-gradient(top, #0088cc, #0055cc);
  background-image: linear-gradient(top, #0088cc, #0055cc);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0055cc', GradientType=0);
  border-color: #0055cc #0055cc #003580;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}
.btn-primary, .btn-primary:hover, .btn-warning, .btn-warning:hover, .btn-danger, .btn-danger:hover, .btn-success, .btn-success:hover, .btn-info, .btn-info:hover, .btn-surprise, .btn-surprise:hover, .btn-inverse, .btn-inverse:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  color: #ffffff  !important;
}
.btn-primary:hover, .btn-primary:active, .btn-primary.active, .btn-primary.disabled, .btn-primary[disabled] {
  background-color: #0055cc;
}
.btn-info {
  background-color: #49afcd;
  background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -ms-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: linear-gradient(top, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5bc0de', endColorstr='#2f96b4', GradientType=0);
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}
.btn-info:hover, .btn-info:active, .btn-info.active, .btn-info.disabled, .btn-info[disabled] {
  background-color: #2f96b4;
}
.btn-success {
  background-color: #5bb75b;
  background-image: -moz-linear-gradient(top, #62c462, #51a351);
  background-image: -ms-linear-gradient(top, #62c462, #51a351);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#62c462), to(#51a351));
  background-image: -webkit-linear-gradient(top, #62c462, #51a351);
  background-image: -o-linear-gradient(top, #62c462, #51a351);
  background-image: linear-gradient(top, #62c462, #51a351);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#62c462', endColorstr='#51a351', GradientType=0);
  border-color: #51a351 #51a351 #387038;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}
.btn-success:hover, .btn-success:active, .btn-success.active, .btn-success.disabled, .btn-success[disabled] {
  background-color: #51a351;
}
.btn-warning {
  background-color: #faa732;
  background-image: -moz-linear-gradient(top, #fbb450, #f89406);
  background-image: -ms-linear-gradient(top, #fbb450, #f89406);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fbb450), to(#f89406));
  background-image: -webkit-linear-gradient(top, #fbb450, #f89406);
  background-image: -o-linear-gradient(top, #fbb450, #f89406);
  background-image: linear-gradient(top, #fbb450, #f89406);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fbb450', endColorstr='#f89406', GradientType=0);
  border-color: #f89406 #f89406 #ad6704;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}
.btn-warning:hover, .btn-warning:active, .btn-warning.active, .btn-warning.disabled, .btn-warning[disabled] {
  background-color: #f89406;
}
.btn-danger {
  background-color: #da4f49;
  background-image: -moz-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: -ms-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ee5f5b), to(#bd362f));
  background-image: -webkit-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: -o-linear-gradient(top, #ee5f5b, #bd362f);
  background-image: linear-gradient(top, #ee5f5b, #bd362f);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ee5f5b', endColorstr='#bd362f', GradientType=0);
  border-color: #bd362f #bd362f #802420;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}
.btn-danger:hover, .btn-danger:active, .btn-danger.active, .btn-danger.disabled, .btn-danger[disabled] {
  background-color: #bd362f;
}
.btn-inverse {
  background-color: #414141;
  background-image: -moz-linear-gradient(top, #555555, #222222);
  background-image: -ms-linear-gradient(top, #555555, #222222);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#555555), to(#222222));
  background-image: -webkit-linear-gradient(top, #555555, #222222);
  background-image: -o-linear-gradient(top, #555555, #222222);
  background-image: linear-gradient(top, #555555, #222222);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#555555', endColorstr='#222222', GradientType=0);
  border-color: #222222 #222222 #000000;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:dximagetransform.microsoft.gradient(enabled=false);
}
.btn-inverse:hover, .btn-inverse:active, .btn-inverse.active, .btn-inverse.disabled, .btn-inverse[disabled] {
  background-color: #222222;
}

/*****************/
.header-naranja{
  background:#f2d0ac;
  color: #343b43;
}
.header-verde{
  background-color: #5bb75b;
  color: white;
}
.header-rojo{
  background-color: #eaaca9;
  color: black;
}
.header-azul{
  background-color: #5077b5;
  color: white;
}

.button-panel{
  margin-right: 10px;
  padding: 8px !important;
  text-align: center;

}

.button-panel .shadow{
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
}

.button-panel button{
  color: rgba(0,0,0,.87);
  background-color: #fff;

  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: inherit;  /*es para que sea una sola linea*/
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  width: 100%;
  line-height: 36px;
  /*padding: 0 16px;*/
  border-radius: 4px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mat-raised-button {
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  line-height: 36px;
}

.titulo_panel{
  font-size: 16px;
  font-weight: bold;
}

.block0 {
  color: #0574b5;
  /*background: #f1f2f3;*/
  text-transform: uppercase;
  border-bottom: 2px solid #0574b5;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  padding-left: 10px;
  /*margin-bottom: 5px;*/
  margin-top: 10px;
  margin-bottom: 10px;
}
.rc-color-picker-trigger {
  border: 1px solid #999;
  display: inline-block;
  /*padding: 2px;*/
  border-radius: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*width: 20px;
  height: 20px;*/
  cursor: pointer;
  box-shadow: 0 0 0 2px #fff inset;
}
/*menu texto rol*/
.menu_text{
  color: #242424;
  font-size: 16px;
  line-height: 50px;
  padding: 0 15px;
  height: 50px;
}
/* color texto menu*/
.link-plomo {
  color: #4b4e51 !important;
}

ul.nu-menu > li > a:hover, .user-menu.nav > li > a:focus {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-grey {
  background-color: #EEE;
}