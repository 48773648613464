
.nu-icon {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: FontAwesome;
    font-size: var(--ag-icon-size);
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: var(--ag-icon-size);
    text-transform: none;
}
[class^="nu-"]::before{
    box-sizing: border-box;
    outline: none;
}
.nu-icon-desc::before { /*down*/
    opacity: .6;
    content: "\f063";
    
}
.nu-icon-asc::before {  /*up*/
    opacity: .6;
    content: "\f062";
    transform: rotate(180deg)
}

/*espacio entre la letra y el icon*/
.nu-sort-indicator-icon {
    padding-left: 5px;
}

.nu-header-cell-sortable {
    cursor: pointer;
}
.nu-cell-label-container {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
    justify-content: space-between;
    overflow: hidden;
    padding: 5px 0;
    width: 100%;
}


