.loading-circle {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 2px solid transparent;
    border-left-color: #ababab;
    animation: circleanimation .45s linear infinite
  }
  .loading-content{
    padding: 10px; 
    margin: 0px;
  }
  .caja_horizontal{
    display: flex;
    flex-direction: row;     /* make main axis horizontal (default setting) */
    justify-content: center; /* center items horizontally, in this case */
    align-items: center;     /* center items vertically, in this case */ 
  }
  
  @keyframes circleanimation {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }