@keyframes material-spinner-rotate {
  0% {
   transform:rotate(0deg)
  }
  100% {
   transform:rotate(360deg)
  }
 }
 @keyframes fabric-spinner-rotate {
  0% {
   transform:rotate(0deg)
  }
  100% {
   transform:rotate(360deg)
  }
 }
 .e-spinner-pane {
  -ms-flex-align:center;
  align-items:center;
  display:-ms-inline-flexbox;
  display:inline-flex;
  height:100%;
  -ms-flex-pack:center;
  justify-content:center;
  left:0;
  position:absolute;
  text-align:center;
  top:0;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none;
  vertical-align:middle;
  width:100%;
  z-index:1000
 }
 .e-spinner-pane::after {
  content:"Material";
  display:none;
 }
 .e-spinner-pane.e-spin-left .e-spinner-inner {
  -webkit-transform:translateX(0%) translateY(-50%);
  left:0;
  padding-left:10px;
  transform:translateX(0%) translateY(-50%)
 }
 .e-spinner-pane.e-spin-right .e-spinner-inner {
  -webkit-transform:translateX(-100%) translateY(-50%);
  left:100%;
  padding-right:10px;
  transform:translateX(-100%) translateY(-50%)
 }
 .e-spinner-pane.e-spin-center .e-spinner-inner {
  -webkit-transform:translateX(-50%) translateY(-50%);
  left:50%;
  transform:translateX(-50%) translateY(-50%)
 }
 .e-spinner-pane.e-spin-hide {
  display:none
 }
 .e-spinner-pane.e-spin-show {
  display:-ms-inline-flexbox;
  /*! display:inline-flex */
 }
 .e-spinner-pane .e-spinner-inner {
  -webkit-transform:translateX(-50%) translateY(-50%);
  left:50%;
  margin:0;
  position:absolute;
  text-align:center;
  top:50%;
  transform:translateX(-50%) translateY(-50%);
  z-index:1000;
 }
 .e-spinner-pane .e-spinner-inner .e-spin-label {
  font-family:"Roboto",Segoe UI;
  font-size:13px;
  margin-top:16px;
  text-align:center
 }
 .e-spinner-pane .e-spinner-inner .e-spin-material {
  animation:material-spinner-rotate 1568.63ms linear infinite;
  display:block;
  margin:0 auto
 }
 .e-spinner-pane .e-spinner-inner .e-spin-material .e-path-circle {
  fill:none;
  stroke-linecap:square
 }
 .e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 {
  animation:material-spinner-rotate .75s linear infinite;
  border-radius:50%;
  display:block;
  margin:0 auto
 }
 .e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 .e-path-circle {
  fill:none;
  stroke-linecap:square;
  stroke-width:4
 }
 .e-spinner-pane .e-spinner-inner .e-spin-fluent,
 .e-spinner-pane .e-spinner-inner .e-spin-fabric {
  animation:fabric-spinner-rotate 1.3s infinite cubic-bezier(0.53, 0.21, 0.29, 0.67);
  display:block;
  margin:0 auto;
  overflow:visible
 }
 .e-spinner-pane .e-spinner-inner .e-spin-fluent .e-path-arc,
 .e-spinner-pane .e-spinner-inner .e-spin-fluent .e-path-circle,
 .e-spinner-pane .e-spinner-inner .e-spin-fabric .e-path-arc,
 .e-spinner-pane .e-spinner-inner .e-spin-fabric .e-path-circle {
  fill:none;
  stroke-width:1.5
 }
 .e-spinner-pane .e-spinner-inner .e-spin-tailwind {
  animation:fabric-spinner-rotate 1.3s infinite cubic-bezier(0.21, 0.21, 0.21, 0.21);
  display:block;
  margin:0 auto;
  overflow:visible
 }
 .e-spinner-pane .e-spinner-inner .e-spin-tailwind .e-path-arc,
 .e-spinner-pane .e-spinner-inner .e-spin-tailwind .e-path-circle {
  fill:none;
  stroke-width:4.5
 }
 .e-spinner-pane .e-spinner-inner .e-spin-bootstrap5 {
  animation:material-spinner-rotate .75s linear infinite;
  border-radius:50%;
  display:block;
  margin:0 auto
 }
 .e-spinner-pane .e-spinner-inner .e-spin-bootstrap5 .e-path-circle {
  fill:none;
  stroke-linecap:square;
  stroke-width:4
 }
 .e-spinner-pane .e-spinner-inner .e-spin-bootstrap {
  display:block;
  margin:0 auto
 }
 .e-spinner-pane .e-spinner-inner .e-spin-high-contrast {
  animation:fabric-spinner-rotate 1.3s infinite cubic-bezier(0.53, 0.21, 0.29, 0.67);
  display:block;
  margin:0 auto;
  overflow:visible
 }
 .e-spinner-pane .e-spinner-inner .e-spin-high-contrast .e-path-arc,
 .e-spinner-pane .e-spinner-inner .e-spin-high-contrast .e-path-circle {
  fill:none;
  stroke-width:1.5
 }
 .e-spinner-pane {
  background-color:rgba(0,0,0,0)
 }
 .e-spinner-pane.e-spin-overlay {
  background-color:rgba(0,0,0,.4)
 }
 .e-spinner-pane.e-spin-overlay .e-spinner-inner .e-spin-label {
  color:#fff
 }
 .e-spinner-pane.e-spin-overlay .e-spinner-inner .e-spin-bootstrap {
  fill:#e3165b;
  stroke:#e3165b
 }
 .e-spinner-pane .e-spinner-inner .e-spin-label {
  color:rgba(0,0,0,.87)
 }
 .e-spinner-pane .e-spinner-inner .e-spin-fluent .e-path-circle,
 .e-spinner-pane .e-spinner-inner .e-spin-fabric .e-path-circle {
  stroke:rgba(227,22,91,.4)
 }
 .e-spinner-pane .e-spinner-inner .e-spin-fluent .e-path-arc,
 .e-spinner-pane .e-spinner-inner .e-spin-fabric .e-path-arc {
  stroke:#e3165b
 }
 .e-spinner-pane .e-spinner-inner .e-spin-tailwind .e-path-circle {
  stroke:rgba(227,22,91,.4)
 }
 .e-spinner-pane .e-spinner-inner .e-spin-tailwind .e-path-arc {
  stroke:#e3165b
 }
 .e-spinner-pane .e-spinner-inner .e-spin-bootstrap5 {
  stroke:#e3165b
 }
 .e-spinner-pane .e-spinner-inner .e-spin-high-contrast .e-path-circle {
  stroke:rgba(227,22,91,.4)
 }
 .e-spinner-pane .e-spinner-inner .e-spin-high-contrast .e-path-arc {
  stroke:#e3165b
 }
 .e-spinner-pane .e-spinner-inner .e-spin-material {
  stroke:#e3165b
 }
 .e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 {
  stroke:#e3165b
 }
 .e-spinner-pane .e-spinner-inner .e-spin-bootstrap {
  fill:#e3165b;
  stroke:#e3165b
 }